import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import JoinUs from '../../sections/joinUs'
import Products from '../../sections/gallery-products'

export default ({ data }) => (
  <Layout>
    <SEO title={`Womens Wedding Bands`} />
    <Products data={data} title={`A selection of womens wedding bandes`}/>
    <JoinUs />
  </Layout>
)

export const WomansBandsQuery = graphql`
  query WomansBandsQuery {
    postgres {
      allProductsItems(first: 100, orderBy: CREATED_AT_DESC, condition: {collection: "WOMENS WEDDING BAND"}) {
        edges{
        node {
          price
          title
          id
          image
          designer
          description
          purchasable
          sku
        }
      }
    }
  }
}
`
